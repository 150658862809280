import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

var firebaseConfig = {
  // Add your production Firebase configuration here
  apiKey: "AIzaSyDwnycq4iol_ekiuLDKq_FNNRykuTLSpvc",
  authDomain: "shortly-b1c8d.firebaseapp.com",
  projectId: "shortly-b1c8d",
  storageBucket: "shortly-b1c8d.appspot.com",
  messagingSenderId: "1098477793560",
  appId: "1:1098477793560:web:4da3abec27ebfa86c36e6d"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();
const auth = firebaseApp.auth();

if (process.env.NODE_ENV === "development") {
  firestore.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
}

export { firebaseApp, firestore, auth, firebase as app };
